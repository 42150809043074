export interface ApiHeaders {
  Authorization?: string;
  'Content-Type'?: string;
  [key: string]: string | undefined;
}

export function apiHeaders(initial: ApiHeaders = {}): ApiHeaders {
  return {
    'Content-Type': 'application/json',
    ...initial,
  };
}
