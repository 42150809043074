import { Details } from '../components/MySubscriptions/state';

export const getEditorSubscriptionDetailsDemo = (shouldShowPaymentMethodDetails: boolean): Details => {
  const paymentMethodDetails = shouldShowPaymentMethodDetails
    ? {
        savedCreditCardDetails: {
          network: 'visa',
          lastFourDigits: '9126',
        },
      }
    : undefined;
  return {
    paymentSubscriptionInfo: {
      totalSuccessfulPayments: 3,
      totalBillingCycles: 6,
      paymentMethod: 'some-payment-method-id',
      lastSuccessfullyChargedDate: new Date(),
      nextChargeAttemptDate: new Date(),
      suspendable: true,
    },
    paymentMethodDetails,
  };
};
